import React from "react";
import "./App.css";
import Main from "./containers/Main";
import NavegacionProvider from "./context/NavegacionProvider";

function App() {
  return (
    <NavegacionProvider>
      <Main />  
    </NavegacionProvider>
    
  );
}

export default App;
