import React, {Component} from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./skillProgress/skillProgress";
// import Projects from "./projects/Projects";
// import StartupProject from "./StartupProjects/StartupProject";
// import WorkExperience from "./workExperience/WorkExperience";
// import Blogs from "./blogs/Blogs";
// import Talks from "./talks/Talks";
// import Podcast from "./podcast/Podcast";
// import Profile from "./profile/Profile";
// import Twitter from "./twitter-embed/twitter";
import Education from "./education/Education";
import Footer from "../components/footer/Footer";
import Top from "./topbutton/Top";
import {StyleProvider} from "../contexts/StyleContext";
import "./Main.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProjectsPortfolio from "./projects/Projects-Portfolio/ProjectsPortfolio";
import Logros from "./achievement/Logros";
import ScrollToTop from "./ScrollToTop";
import SkillsGeneral from "../components/skillsGeneral/SkillsGeneral";
import ContactPage from "../components/ContactPage/ContactPage";
import Contact from "./contact/Contact";
import ProjectsPage from "./projects/Projects-Portfolio/ProjectsPage";
import BackendProjects from "./projects/Projects-Portfolio/BackendProjects/BackendProjects";
import AIProjets from "./projects/Projects-Portfolio/AIProjets/AIProjets";
import MobilApps from "./projects/Projects-Portfolio/MobilApps/MobilApps";
// import IMEprojects from "./projects/Projects-Portfolio/IMEprojects/IMEprojects";


export default class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDark: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isDark") === null) {
      const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
      localStorage.setItem("isDark", darkPref.matches);
    }
    this.setState({isDark: JSON.parse(localStorage.getItem("isDark"))});
  }

  changeTheme = () => {
    this.setState({isDark: !this.state.isDark}, () => {
      localStorage.setItem("isDark", this.state.isDark);
    });
  };

  render() {
    return (
      <div className={this.state.isDark ? "dark-mode" : null}>
        <StyleProvider
          value={{isDark: this.state.isDark, changeTheme: this.changeTheme}}
        >
          <Router>
            <Header />
            <div>
              <Switch>
                {/* <Route path="/projects/ime">
                  <IMEprojects />
                </Route> */}
                <Route path="/projects/mobile-app">
                  <MobilApps />
                </Route>
                <Route path="/projects/ai">
                  <AIProjets />
                </Route>
                <Route path="/projects/backend">
                  <BackendProjects />
                </Route>
                <Route path="/projects/frontend">
                  <ProjectsPortfolio />
                </Route>
                <Route path="/projects">
                  <ScrollToTop/>
                  {/* <StartupProject /> */}
                  {/* <ProjectsPortfolio /> */}
                  <ProjectsPage/>
                </Route>
                <Route path="/achievements">
                  <ScrollToTop/>
                  <Logros />
                </Route>
                <Route path="/skills">
                  <ScrollToTop/>
                  <SkillsGeneral />
                </Route>
                <Route path="/contact">
                  <ScrollToTop/>
                  <ContactPage/>
                </Route>
                <Route exact path="/">
                  <ScrollToTop/>
                  <Greeting />
                  <Skills />
                  <StackProgress />
                  <Education />
                  <Contact />
                  {/* <WorkExperience />
                  <Blogs />
                  <Talks />
                  <Twitter />
                  <Podcast />
                  <Profile /> */}
                </Route>
                <Redirect to="/" />
              </Switch>  
            </div>
            <Footer />
            <Top />
          </Router>
        </StyleProvider>
      </div>
    );
  }
}
