import React from 'react'
import Achievement from './Achievement'
import Certificates from './certificates/Certificates'

const Logros = () => {
    return (
        <div>
            <Achievement />
            <Certificates />
        </div>
    )
}

export default Logros
