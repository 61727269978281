import React, {useContext} from "react";
import "./Achievement.css";
import AchievementCard from "../../components/achievementCard/AchievementCard";
import {achievementSection, achievementSectionES} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { NavegacionContext } from "../../context/NavegacionProvider";
export default function Achievement() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext);
  
  if (!achievementSection.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="achievements">
        <div className="achievement-main-div">
          <div className="achievement-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading achievement-heading"
                  : "heading achievement-heading"
              }
            >
              {navIdiom === 'EN' && achievementSection.title}
              {navIdiom === 'ES' && achievementSectionES.title}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle achievement-subtitle"
                  : "subTitle achievement-subtitle"
              }
            >
              {navIdiom === 'EN' && achievementSection.subtitle}
              {navIdiom === 'ES' && achievementSectionES.subtitle}
            </p>
          </div>
          {navIdiom === 'EN' && (
            <div className="achievement-cards-div">
              {achievementSection.achievementsCards.map((card, i) => {
                return (
                  <AchievementCard
                    key={i}
                    isDark={isDark}
                    cardInfo={{
                      title: card.title,
                      description: card.subtitle,
                      image: card.image,
                      footer: card.footerLink
                    }}
                  />
                );
              })}
            </div>
          )}
          {navIdiom === 'ES' && (
            <div className="achievement-cards-div">
            {achievementSectionES.achievementsCards.map((card, i) => {
              return (
                <AchievementCard
                  key={i}
                  isDark={isDark}
                  cardInfo={{
                    title: card.title,
                    description: card.subtitle,
                    image: card.image,
                    footer: card.footerLink
                  }}
                />
              );
            })}
          </div>
          )}
         
        </div>
      </div>
    </Fade>
  );
}
