import React from 'react'
import SmallProjectsCard from "./smallProjects/SmallProjectsCard";
import emoji from "react-easy-emoji";

const ReactJS = () => {
    return (
        <>
            <SmallProjectsCard 
                    title= {emoji("⭐ Blasphemous ⭐")}
                    elements = {['React App','Hooks']}
                    url = {'https://blasphemous.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            
            <SmallProjectsCard 
                    title= {emoji("⭐ Notas ⭐")}
                    elements = {['React App','Hooks', 'Redux', 'Firebase y Cloudinary']}
                    url = {'https://crud-firestore-c1f4e.web.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            
            <SmallProjectsCard 
                    title= {'Bebidas'}
                    elements = {['React App','Hooks', 'Context API', 'Axios']}
                    url = {'https://xenodochial-kalam-6f2451.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                    title= {'GrupoLetra'}
                    elements = {['React App','Hooks', 'Context API', 'Axios', 'Parallel Requests']}
                    url = {'https://boring-brown-4b346a.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                    title= {'PixabayAPI'}
                    elements = {['React App','Hooks', 'Axios', 'Pagination']}
                    url = {'https://clever-carson-3c808e.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                    title= {'MarvelvsDC'}
                    elements = {['React App','Hooks', 'Protected Routes','BD Local']}
                    url = {'https://tender-ritchie-913ab3.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                    title= {'Pacientes'}
                    elements = {['React App','Hooks', 'Local Storage', 'PropTypes']}
                    url = {'https://admiring-meitner-a1173c.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                    title= {'Presupuesto'}
                    elements = {['React App','Hooks', 'PropTypes']}
                    url = {'https://naughty-tereshkova-e18560.netlify.app/'}
                    type = 'react'
                    buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                title= {'BuscadorGif'}
                elements = {['React App','Hooks', 'Fetch API']}
                url = {'https://goofy-mayer-c69f3f.netlify.app/'}
                type = 'react'
                buttontext={['Show Demo','Ver Demo']}
            />
        </>
    )
}

export default ReactJS

