import React, { createContext, useState, useEffect } from 'react'

export const NavegacionContext = createContext();

const NavegacionProvider = (props) => {

    const [navIdiom, setNavIdiom] = useState('EN');

    useEffect(() => {
        if(localStorage.getItem("idiom") === null ){
            setNavIdiom('EN');
        }else{
            setNavIdiom(localStorage.getItem("idiom")); 
        }
    }, [navIdiom])

    return (
        <NavegacionContext.Provider
            value={{
                navIdiom,
                setNavIdiom
            }}
        >
            {props.children}
        </NavegacionContext.Provider>
    )
}

export default NavegacionProvider
