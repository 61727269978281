import React, { useContext } from 'react'
import { NavegacionContext } from '../../../../context/NavegacionProvider';

const SmallProjectsCard = ({title, elements, url, type, buttontext}) => {

    const {navIdiom} = useContext(NavegacionContext);

    return (
        <div className={`${type} card`}>
            <div className="content">
            <h2 className="title">{title}</h2>
            <p className="copy">
                {elements.map((element, key) => (
                    <li key={key}>
                            {element}
                    </li>
                ))}
            </p>
                <a 
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                <button className="btn">
                    {navIdiom === 'EN' && buttontext[0]}
                    {navIdiom === 'ES' && buttontext[1]}
                </button></a>
            </div>
        </div>
    )
}

export default SmallProjectsCard
