import React, {useContext} from "react";
import "../ProjectsPortfolio.css";
// import emoji from "react-easy-emoji";
import {Fade} from "react-reveal";
import StyleContext from "../../../../contexts/StyleContext";
import ScrollToTop from "../../../ScrollToTop";
import { NavegacionContext } from "../../../../context/NavegacionProvider";
import ReactNative from "./ReactNative";

const MobilApps = () => {
    const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext)
  return (
    <>
        <ScrollToTop/>
        <Fade bottom duration={1000} distance="20px">
            <div className="main" id="projects">
            <div>
                <h1 className="skills-heading"><div className="titulo_react">React Native</div></h1>
                
                <p
                className={
                    isDark
                    ? "dark-mode"
                    : "subTitle"
                }
                >
                {navIdiom === 'EN' && 'React Native is a framework that allows us to create native applications for iOS and Android. This framework is based on React JS. The difference of using React Native is that, instead of getting a hybrid web application, we get a fully native application, either for iOS or Android based operating systems. Native applications, with respect to hybrid applications, have better performance, lower memory consumption and higher speed.'}
                {navIdiom === 'ES' && 'React Native es un framework que nos permite crear aplicaciones nativas para iOS y Android. Este framework está basado en React JS. La diferencia de utilizar React native es que, en lugar de obtener una aplicación web híbrida, obtenemos una aplicación totalmente nativa, ya sea para sistemas operativos basados en iOS como Android. Las aplicaciones nativas, respecto de las aplicaciones híbridas, tienen mejor rendimiento, menor consumo de memoria y mayor velocidad.'}
                </p>
            </div>
            <main className="page-content">
                <ReactNative />
            </main>
            </div>
        </Fade>
    </>
  );
}

export default MobilApps