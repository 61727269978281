import React from 'react';
import './certificates.css';
import {Fade} from "react-reveal";
import growth from "../../../assets/lottie/growth.json";
import DisplayLottie from "../../../components/displayLottie/DisplayLottie";

const Certificates = () => {

    return (
        <>
        <div className="certificates_container">
            <Fade left duration={1000}>
                <a 
                    href="https://skillshop.exceedlms.com/student/award/73198273"
                    target="_blank"
                    rel="noopener noreferrer"
                >Google - Campaign Manager Certification Exam</a>
                <a 
                    href="https://www.coursera.org/account/accomplishments/verify/777L9BZY96ZL?utm_source=ln&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course"
                    target="_blank"
                    rel="noopener noreferrer"
                >deeplearning.ai - Neural Networks and Deep Learning</a>
                <a 
                    href="https://www.coursera.org/account/accomplishments/verify/B7NE436R8QF6?utm_source=ln&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=project"
                    target="_blank"
                    rel="noopener noreferrer"
                >IBM - Building Smart Business Assistants with IBM Watson</a>
                <a 
                    href="https://www.coursera.org/account/accomplishments/verify/597BGWTHEWK7?utm_source=ln&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course"
                    target="_blank"
                    rel="noopener noreferrer"
                >IBM - What is Data Science?</a>
                <a 
                    href="https://drive.google.com/file/d/1vjRo790l9BuIWopT4jhfShEptqfa8o0z/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >Stanford - Introduction to the Internet of Things</a>
                <a 
                    href="https://drive.google.com/file/d/1pZQ4IKoSMl8_PQlmRYAE1JxumA7Ft8ys/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >Asistencia al evento "Semana de los Drones"</a>
                <a 
                    href="https://drive.google.com/file/d/1pZQ4IKoSMl8_PQlmRYAE1JxumA7Ft8ys/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >IEEE RAS UDEP Chapter Vice Chair</a>
                <a 
                    href="https://drive.google.com/file/d/1p6ZYZbN34PrmbCnrjBPdL5KaDc9UK44m/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >Curso de DIgSILENT DPL Básico</a>
                <a 
                    href="https://drive.google.com/file/d/1GOYYuhj3DWY7_f6QNS6D6Ms-K5Ya8jJ3/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >2do Lugar - Humanitarian and Inclusive Projects Challenge 2020</a>
                <a 
                    href="https://drive.google.com/file/d/1xTJwGme593CbE4xjcV6zN-yHsr9F7P8-/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >Eircon Presenter Certificate 2020</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-528b1206-27f2-4147-b7d8-487bb4fe7e40/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Build Responsive Website Using HTML5, CSS3, JS And Bootstrap</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-1adda809-5a55-4503-883b-b660bfbae19b/"
                    target="_blank"
                    rel="noopener noreferrer"
                >AWS Networking Masterclass - Amazon VPC and Hybrid Cloud</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-4453786d-2d94-4082-852d-af9f3807b0c5/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Aprende Typescript de 0 a 100</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-651a89df-4632-4122-be43-f6a36f397b1c/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Aprende JavaScript y Jquery de 0 a 100</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-8549cfd0-7b58-4e6f-8069-228b47c14cb2/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Automate the Boring Stuff with Python Programming</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-c3c3faad-ff28-44ab-a702-650834984eb6/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Bootstrap From Scratch - Fast and Responsive Web Development</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-49d4981f-2cfd-4e04-9244-00be79972ec4/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Machine Learning and Deep Learning in Python and R</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-39b60990-6966-4990-b15c-338dc55cb51d/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Git and GitHub Crash Course</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-f6889025-5b8e-4489-9ad0-b3d47281c67d/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Learn C# and make a videogame with Unity 2020</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-ed3955d1-285f-4d92-bd96-863d413a9554/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Análisis Básico de Estados Financieros</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-669f432f-af08-48b4-9e61-58e8dd205d7f/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Desarrollo web. JavaScript desde cero. Edición 2020</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-d2f56a79-dea1-41e0-904e-72133654a086/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Excel Lessons - Zero to Pro for Teachers and Office Workers</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-e8afd260-bd8b-4c9d-b19f-cf2a1c6c0e27/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Fundamentos de VueJS 3</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-d7603f5a-0a8e-4a38-b53b-e8fe25fdac09/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Complete Python Bootcamp : Go Beginner to Expert in Python 3</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-9acdcd3a-d165-497e-b4e0-21d7da390b7b/"
                    target="_blank"
                    rel="noopener noreferrer"
                >The Python MicroDegree: From Basics To Building Python Apps</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-25688bdf-520c-4e50-8f7e-30f8ed1c4535/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Desarrollo web. CSS desde cero. Edición 2020</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-7a3bfe4e-39b3-41ee-abb7-01a12bbd1422/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Microsoft Power BI - Curso de Power BI Desktop</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-5eb57398-52b1-43cb-b807-29c820483f6d/"
                    target="_blank"
                    rel="noopener noreferrer"
                >React.JS, Guia desde 0 (Actualizado 2021)</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-9d707e9e-b82d-4e1c-9278-4a11d67a7ab3/"
                    target="_blank"
                    rel="noopener noreferrer"
                >React Native bootcamp - Build an Instagram Clone w/Firebase</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-bc39be50-e6d3-49f0-b2b4-c0551659a3aa/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Full Stack JavaScript Developer en Español</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-ce40db0b-f926-4a2b-8a58-1cec30ec5397/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Machine Learning - Regression and Classification (math Inc.)</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-0e12a6d6-2353-4fca-ac0e-9ae05fb427c9/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Code with Node JS - 2021</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-43acd04c-8ec7-4073-8048-e3bbd51aaf1e/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Aprende Vue + Firebase ACTUALIZADO 2020</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-b079eb4c-109b-4254-8e2a-0f03a92067ed/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Aprende React + Firebase | 2021 Actualizado</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-d6f4735c-92dc-49d3-8bda-ac3bacc0f4b0/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Aprende lenguaje GO (GOLANG) desde 0</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-ed064e10-e4c8-499c-87b6-019e9b884a64/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Management Consulting Skills Mastery</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-a33a7edc-a325-465e-bafa-877829f1e7e4/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Python And Django Framework For Beginners Complete Course</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-798bcbc5-9e40-4fca-b770-ad8ac4754520/"
                    target="_blank"
                    rel="noopener noreferrer"
                >How to write cleaner code with Kotlin on Android</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-d634c427-ec55-46ac-a3cd-47f119a993ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Python Programming - The Complete Guide [2021 Edition]</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-68537ff8-f19d-4ef0-b7f4-57f30765070c/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Desarrollo De Aplicaciones Web Con JavaScript (Edición 2021)</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-d5117cea-e10a-49e0-9282-2992bfc13bbc/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Complete Graphics Design and Video Editing Masterclass</a>
                <a 
                    href="https://www.udemy.com/certificate/UC-afeb6e3c-be7d-49fe-ba20-e1ab81a8c5a9/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Professional Adobe Photoshop CC Course With Advance Training</a>
                
                
            </Fade>

            <Fade left duration={1000}>
                <div className="skills-image-div">   
                    <DisplayLottie animationData={growth} />
                </div>
            </Fade>
            
        </div>
    </>
    )
}

export default Certificates
