import React from "react";
// import emoji from "react-easy-emoji";
import {Fade} from "react-reveal";
import StartupProject from "../../StartupProjects/StartupProject";


export default function ProjectsPage() {
  return (
    <>
    <Fade bottom duration={1000} distance="20px">
      <StartupProject />
    </Fade>
  </>
  );
}
