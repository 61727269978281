import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'

const HardSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
        <h2 className="h2-skills">
            {navIdiom === 'EN' && 'Hard Skills'}
            {navIdiom === 'ES' && 'Habilidades duras'}
        </h2>
            {navIdiom === 'EN' && (
                <div className="soft-skills">
                    <p>&#10003; Knowledge of Humanities.</p>
                    <p>&#10003; Engineering Sciences.</p>
                    <p>&#10003; Knowledge of Electrical.</p>
                    <p>&#10003; Knowledge of Mechanics and Materials.</p>
                    <p>&#10003; Knowledge of Energy.</p>
                    <p>&#10003; Knowledge of Electronics and Control.</p>
                    <p>&#10003; Economic and Administrative Sciences.</p>
                    <p>&#10003; Advanced Frontend Knowledge.</p>
                    <p>&#10003; Medium Backend Knowledge.</p>
                    <p>&#10003; Knowledge of SEO.</p>
                    <p>&#10003; Graphic design skills.</p>
                    <p>&#10003; Create Apps Using Python.</p>
                    <p>&#10003; Development of Mobile Apps.</p>
                    <p>&#10003; Cloud computing knowledge.</p>
                    <p>&#10003; Great athlete.</p>
                </div>
            )}
            {navIdiom === 'ES' && (
                <div className="soft-skills">
                    <p>&#10003; Conocimiento de Humanidades.</p>
                    <p>&#10003; Ciencias de la Ingeniería.</p>
                    <p>&#10003; Conocimiento de Electricidad.</p>
                    <p>&#10003; Conocimiento de Mecánica y materiales.</p>
                    <p>&#10003; Conocimiento de Energía.</p>
                    <p>&#10003; Conocimiento de Electrónica y control.</p>
                    <p>&#10003; Ciencias Económicas y Administrativas.</p>
                    <p>&#10003; Conocimiento avanzado de Fronted.</p>
                    <p>&#10003; Conocimiento medio de backend.</p>
                    <p>&#10003; Conocimiento de SEO.</p>
                    <p>&#10003; Habilidades de diseño gráfico.</p>
                    <p>&#10003; Crear aplicaciones usando Python.</p>
                    <p>&#10003; Desarrollo de aplicaciones móviles.</p>
                    <p>&#10003; Conocimiento de servicios de nube.</p>
                    <p>&#10003; Gran deportista.</p>
                </div>
            )}
        </>
    )
}

export default HardSkills


