import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'
import AppSVG from './AppSVG';

const AppSkills = () => {

    const {navIdiom} = useContext(NavegacionContext);

    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Mobile Development Technologies'}
                {navIdiom === 'ES' && 'Tecnologías de Desarrollo Móbil'}
            </h2>
            <AppSVG />
        </>
    )
}

export default AppSkills
