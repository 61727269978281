import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'

const SoftSkill = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
        <h2 className="h2-skills">
            {navIdiom === 'EN' && 'Soft Skills'}
            {navIdiom === 'ES' && 'Habilidades Blandas'}
        </h2>
            {navIdiom === 'EN' && (
                <div className="soft-skills">
                    <p>&#10003; Development of ideas and capacity to innovate.</p>
                    <p>&#10003; Identification of opportunities and problem solving.</p>
                    <p>&#10003; Mastery of new technologies.</p>
                    <p>&#10003; Communication skills.</p>
                    <p>&#10003; Commitment to the company.</p>
                    <p>&#10003; Teamwork.</p>
                    <p>&#10003; Ability to manage stress.</p>
                    <p>&#10003; Adaptation / flexibility.</p>
                    <p>&#10003; Achievement orientation.</p>
                </div>
            )}
            {navIdiom === 'ES' && (
                <div className="soft-skills">
                    <p>&#10003; Desarrollo de ideas y capacidad para innovar.</p>
                    <p>&#10003; Identificación de oportunidades y resolución de problemas.</p>
                    <p>&#10003; Dominios de las nuevas Tecnologías.</p>
                    <p>&#10003; Capacidad de comunicación.</p>
                    <p>&#10003; Compromiso con la compañia.</p>
                    <p>&#10003; Trabajo en equipo.</p>
                    <p>&#10003; Capacidad para gestionar el estrés.</p>
                    <p>&#10003; Adaptación / flexibilidad.</p>
                    <p>&#10003; Orientación al logro.</p>
                </div>
            )}
        </>
    )
}

export default SoftSkill
