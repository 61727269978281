import React, {useContext} from "react";
import "./Footer.css";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import { NavegacionContext } from "../../context/NavegacionProvider";
import ReactLogo from "./ReactLogo";

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom, setNavIdiom} = useContext(NavegacionContext);
  const cambioIdioma = (idioma) => {
    setNavIdiom(idioma);
    localStorage.setItem("idiom", idioma);
  }
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <div className="footer-div-text">
          <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
            {navIdiom === 'EN' && "This is my Resume / Portfolio WebPage"}
            {navIdiom === 'ES' && "Esta es mi Web CV / Portafolio"}
          </p>
          <span className={isDark ? "dark-mode footer-text" : "footer-text"}>
            {navIdiom === 'EN' && "This page was made whit "}
            {navIdiom === 'ES' && "Esta página fue hecha con "}
            {" "}
            <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
            <ReactLogo />
            </a>
            <a className="mobil-text-react-logo" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
            React
            </a>
          </span> 
          
        </div>
        <div
          className={isDark ? `dark-mode footer-button 
          ${navIdiom === 'EN' && "footer-button-active"}`
          : `footer-button 
          ${navIdiom === 'EN' && "footer-button-active"}`
        }
          onClick={()=> cambioIdioma('EN')}
        >
          English{emoji("🇺🇸")}
        </div>
        <div
          className={isDark ? `dark-mode footer-button 
                    ${navIdiom === 'ES' && "footer-button-active"}`
                    : `footer-button 
                    ${navIdiom === 'ES' && "footer-button-active"}`
                  }
          onClick={()=> cambioIdioma('ES')}
        >
          Español{emoji("🇵🇪")}
        </div>
      </div>
    </Fade>
  );
}
