import React from 'react'
import SmallProjectsCard from "./smallProjects/SmallProjectsCard";

const VanillaJS = () => {
    return (
        <>
            <SmallProjectsCard 
                    title= {'Venta de Casas'}
                    elements = {['HTML','Sass', 'JavaScript']}
                    url = {'https://suspicious-borg-9ad1de.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'Copia Netflix'}
                    elements = {['HTML','Sass', 'JavaScript']}
                    url = {'https://elated-easley-580c7c.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'Hospedaje'}
                    elements = {['HTML','Sass', 'JavaScript']}
                    url = {'https://naughty-bose-a14f33.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'CursosCarrito'}
                    elements = {['HTML','Sass', 'JavaScript', 'IndexedDB']}
                    url = {'https://compassionate-bhaskara-b34ec2.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'Criptomonedas'}
                    elements = {['HTML','CSS', 'JavaScript', 'Promises', 'Callbacks']}
                    url = {'https://hopeful-perlman-8f0df8.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'Pixabay'}
                    elements = {['HTML','CSS', 'JavaScript', 'Async Await', 'Fetch API']}
                    url = {'https://gracious-shockley-a28884.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                {/* <SmallProjectsCard 
                    title= {'CRUD Veterinaria'}
                    elements = {['HTML','CSS', 'JavaScript']}
                    url = {'https://gracious-shockley-a28884.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                />
                <SmallProjectsCard 
                    title= {'CRUD Usuarios'}
                    elements = {['HTML','CSS', 'JavaScript', 'Asincronismo', 'Async Await', 'Fetch API']}
                    url = {'https://gracious-shockley-a28884.netlify.app/'}
                    type = 'js'
                    buttontext={['Show Demo','Ver Demo']}
                /> */}
        </>
    )
}

export default VanillaJS
