import React, {useContext}  from 'react'
import imgDL from '../../../../assets/images/projects/Python/deeplearning.png'
import StyleContext from "../../../../contexts/StyleContext";
import {Fade} from "react-reveal";
import { NavegacionContext } from '../../../../context/NavegacionProvider';

const DLProjects = () => {
    const {isDark} = useContext(StyleContext);
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <Fade bottom duration={1000} distance="20px">
        <div className="main">
            <h1 className="skills-heading"><div className="titulo_react">Deep Learning</div></h1>
            <div className="two-col-backend">
                <div className="twoCol-img-project">
                    <img src={imgDL} alt="WS"/>
                </div>
                <div className="twoCol-info-project">
                    <p
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                    >
                        {navIdiom === 'EN' && 'Deep learning uses neural network algorithms. These algorithms make it possible for machines to be able to solve complex problems with the help of a very diverse and messy data set. By using this data, the NN train themselves, learning to recognize repeating patterns, specific words, frequent behaviors, etc.'}     
                        {navIdiom === 'ES' && 'El deep learning o aprendizaje profundo usa algoritmos de Redes Neuronales. Estos algoritmos hacen posible que las máquinas sean capaces de resolver problemas complejos con la ayuda de un conjunto de datos muy diversos y desordenados. Mediante el uso de estos datos, las RN se entrenan a sí mismas, aprendendiendo a reconocer patrones de repetición, palabras concretas, comportamientos frecuentes, etc.'}
                    </p>  
                </div>
            </div>
            <div className="two-col-backend">
                <h2
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                    >
                    {navIdiom === 'EN' && 'Supervised Learning'}
                    {navIdiom === 'ES' && 'Aprendizaje Supervisado'}
                </h2>
            </div>
            <div className="two-col-backend">
                <div className="twoCol-btn-center">
                    
                    <a href="https://github.com/gleenpeltroche/Python-DL/blob/main/Convolutional%20Neural%20Networks%20(CNN)/cnn.py" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Image Classification'}
                        {navIdiom === 'ES' && 'Clasificador de imagenes'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-DL/blob/main/Artificial%20Neural%20Networks%20(ANN)/NN_Classification_Model.py" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Customer Retention'}
                        {navIdiom === 'ES' && 'Retención de clientes'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-DL/blob/main/Artificial%20Neural%20Networks%20(ANN)/NN_Regresion_Model.py" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Prediction'}
                        {navIdiom === 'ES' && 'Predicción'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-DL/blob/main/Recurrent%20Neural%20Networks%20(RNN)/rnn.py" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Forecasting'}
                        {navIdiom === 'ES' && 'Previsión'}
                    </a>
                       
                </div>
            </div>
            {/* <div className="two-col-backend">
                <h2
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                    >Aprendizaje NO Supervisado</h2>
            </div>
            <div className="two-col-backend">
                <div className="twoCol-btn-center">
                    
                        <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS1-StaticPages" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                            Image Classification
                        </a>
                    
                        <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS2-MultiPagesSameDomain" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                            Customer Retention
                        </a>
                    
                        <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS3-DinamicPages" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                            Prediction
                        </a>
                    
                        <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS4-APIs-iframes" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                            Forecasting
                        </a>
                       
                </div>
            </div>           */}
        </div>
        </Fade>
    )
}

export default DLProjects
