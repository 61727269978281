import React from 'react'
import './FrontedSVG.css'

const DesingSVG = () => {
    return (
        <div className="contenedor-svg">
        <li className="software-skills">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 512 512">
                <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>
                <path d="M213.102,181.6h-72.24V352h33.12v-57.12h40.32c29.531,0,53.04-25.713,53.04-56.64
                    C267.342,212,246.166,181.6,213.102,181.6z M212.382,265.84h-38.4v-55.2h36.96c14.057,0,22.8,13.2,22.8,27.6
                    C233.742,255,224,265.84,212.382,265.84z"/>
                <path d="M336.939,276.64c-4.642-1.118-24.72-4.39-24.72-15.84c0-9.175,7.531-14.16,19.2-14.16c15.831,0,30.079,9.12,36.48,13.92
                    l12.96-19.44c-6.881-6.079-21.609-17.28-49.2-17.28c-26.284,0-49.44,14.91-49.44,41.52c0,27.64,36.72,32.801,44.88,35.04
                    c9.757,2.723,27.6,6.225,27.6,17.04c0,8.81-8.449,13.92-20.64,13.92c-18.809,0-36.48-11.119-44.16-16.56l-12,20.16
                    c7.2,6.24,27.851,19.44,56.88,19.44c15.679,0,50.88-4.9,50.88-39.84C385.659,285,345.579,278.882,336.939,276.64z"/>
            </svg>
            <p>Photoshop</p>
        </li>
        <li className="software-skills">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 512 512">
                <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>

                <path d="M229.18,181.6h-72.24V352h33.12v-57.12h40.32c29.531,0,53.04-25.713,53.04-56.64C283.42,212,262.244,181.6,229.18,181.6z
                        M228.461,265.84h-38.4v-55.2h36.96c14.057,0,22.8,13.2,22.8,27.6C249.821,255,240.079,265.84,228.461,265.84z"/>

                <path d="M378.217,254.08V224.8c-29.04-3.05-43.121,24-45.36,28.32v-26.88h-29.52V352h32.16v-76.08
                    C338.857,268.72,349.927,254.08,378.217,254.08L378.217,254.08z"/>
            </svg>
            <p>Premiere Pro</p>
        </li>
        <li className="software-skills">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 512 512" >
                    <polygon points="266.941,181.6 231.181,181.6 188.941,244.96 146.461,181.6 110.461,181.6 171.182,268 112.382,352 148.382,352 
                        188.941,291.04 229.261,352 265.021,352 206.221,268 		"/>
                    <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>
                    <path d="M396.779,315.04V176.8h-32.16v71.52c-4.162-7.2-17.217-24.24-42.479-24.24c-29.821,0-56.16,25.92-56.16,65.04
                        c0,34.505,22.839,65.28,59.761,65.28c25.203,0,39.037-16.237,43.678-24.24c0,0-2.102,23.52,21.119,23.52
                        c3.518,0,8.078-0.562,13.681-1.68v-27.36C401.497,324.482,396.779,318.4,396.779,315.04z M364.62,305.92
                        c-1.121,3.041-10.3,21.12-30.479,21.12c-21.322,0-34.8-18.873-34.8-37.2c0-16.673,10.979-38.4,32.88-38.4
                        c19.598,0,31.117,20.962,32.399,24.48V305.92z"/>
            </svg>
            <p>Adobe Xd</p>
        </li>
        <li className="software-skills">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g transform="matrix(1 0 0 1 -40 -40)"><rect width="24" height="24" x="40" y="40" fill="none"/><path d="M144.49,381.147L151.51,381.147C153.93,381.147 155.9,383.062 155.9,385.361C155.9,386.705 155.226,387.919 154.182,388.694C155.226,389.47 155.9,390.683 155.9,392.028C155.9,394.326 153.93,396.241 151.51,396.241C150.524,396.241 149.613,395.924 148.88,395.392L148.88,398.694C148.88,400.993 146.911,402.908 144.49,402.908C142.07,402.908 140.1,400.993 140.1,398.694C140.1,397.35 140.774,396.137 141.818,395.361C140.774,394.585 140.1,393.372 140.1,392.028C140.1,390.683 140.774,389.47 141.818,388.694C140.774,387.919 140.1,386.705 140.1,385.361C140.1,383.062 142.07,381.147 144.49,381.147ZM147.12,396.241L144.49,396.241C143.06,396.241 141.86,397.336 141.86,398.694C141.86,400.053 143.06,401.147 144.49,401.147C145.921,401.147 147.12,400.053 147.12,398.694L147.12,396.241ZM148.88,392.028C148.88,390.669 150.079,389.574 151.51,389.574C152.94,389.574 154.14,390.669 154.14,392.028C154.14,393.386 152.94,394.481 151.51,394.481C150.079,394.481 148.88,393.386 148.88,392.028ZM144.457,389.575C143.041,389.591 141.86,390.68 141.86,392.028C141.86,393.386 143.06,394.481 144.49,394.481L147.12,394.481L147.12,389.574L144.49,389.574L144.457,389.575ZM147.12,387.814L147.12,382.908L144.49,382.908C143.06,382.908 141.86,384.002 141.86,385.361C141.86,386.72 143.06,387.814 144.49,387.814L147.12,387.814ZM148.88,387.814L151.51,387.814C152.94,387.814 154.14,386.72 154.14,385.361C154.14,384.002 152.94,382.908 151.51,382.908L148.88,382.908L148.88,387.814Z" transform="translate(-96 -340)"/></g></svg>
            <p>Figma</p>
        </li>
        </div>
    )
}

export default DesingSVG

