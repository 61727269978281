import React, {useContext} from "react";
import "./ContactPage.css";
import SocialMedia from "../socialMedia/SocialMedia";
import {contactInfo, contactInfoES} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import emoji from "react-easy-emoji";
import { NavegacionContext } from "../../context/NavegacionProvider";
import imgContact from '../../assets/images/contact.png'


export default function ContactPage() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext);
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">
              {navIdiom === 'EN' && emoji("Contact Me 📝")}
              {navIdiom === 'ES' && emoji("Contáctame 📝")}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {navIdiom === 'EN' &&  contactInfo.subtitle}
              {navIdiom === 'ES' && contactInfoES.subtitle}
            </p>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              <span>
                {navIdiom === 'EN' && 'Phone Number: +51-980542495'}
                {navIdiom === 'ES' && 'Telefono: +51-980542495'}
              </span><br />
              <a className="contact-details" href="https://wa.me/51980542495" target="_blank" rel="noopener noreferrer">
                WhatsApp  {emoji("💬")}
              </a>
              <a className="contact-details" href={"tel:" + contactInfo.number}>
                {navIdiom === 'EN' && 'Call the phone'}
                {navIdiom === 'ES' && 'Llamar al telefono'}
                {emoji("📲")}
              </a><br /><br />
              <span>
                E-mail: gleenpeltroche@gmail.com
              </span><br />
              <a
                className="contact-details-email"
                href={"mailto:" + contactInfo.email_address}
              >
                {navIdiom === 'EN' && 'Send an Email'}
                {navIdiom === 'ES' && 'Enviar un Email'}
                {emoji("📩")}
              </a><br />
              <br />
              <br />
              <br />
              <SocialMedia />
            </div>
          </div>
          <div className="contact-img">
            <img src={imgContact} alt="Imagen Contacto"/>
          </div>
        </div>
      </div>
    </Fade>
  );
}
