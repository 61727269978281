import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'
import BackendSVG from './BackendSVG'

const BackendSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Backend Technologies'}
                {navIdiom === 'ES' && 'Tecnologías Backend'}
            </h2>
            <BackendSVG />  
        </>
    )
}

export default BackendSkills
