import React from 'react'
import SmallProjectsCard from "./smallProjects/SmallProjectsCard";
import emoji from "react-easy-emoji";

const NextJS = () => {

    return (
        <>
            <SmallProjectsCard 
                title= {'CoinDataMining'}
                elements = {['React Next','Hooks', 'Axios', 'RealTime Data', 'Chart']}
                url = {'https://coindatamining.vercel.app/'}
                type = 'next'
                buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                title= {emoji("⭐ ProductHunt ⭐")}
                elements = {['React Next','Hooks', 'Firebase', 'Firestore', 'Auth']}
                url = {'https://next-product-hunt-n1rfwgxwx-gleenpeltroche.vercel.app/'}
                type = 'next'
                buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                title= {emoji("⭐ PrivateCRM ⭐")}
                elements = {['React Next','Hooks', 'MongoDB', 'Apollo', 'GraphQL']}
                url = {'https://crmgraphqlcliente-gr1pijhe3-gleenpeltroche.vercel.app/login'}
                type = 'next'
                buttontext={['Show Demo','Ver Demo']}
            />
            <SmallProjectsCard 
                title= {'NodeSend'}
                elements = {['React Next','Hooks', 'MongoDB', 'DB Storage', 'Auth']}
                url = {'https://nodesend-121ppni00-gleenpeltroche.vercel.app/'}
                type = 'next'
                buttontext={['Show Demo','Ver Demo']}
            />
        </>
    )
}

export default NextJS
