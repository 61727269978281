import React, {useContext}  from 'react'
import imgWS from '../../../../assets/images/projects/backend/node.png'
import StyleContext from "../../../../contexts/StyleContext";
import {Fade} from "react-reveal";
import { NavegacionContext } from '../../../../context/NavegacionProvider';


const NodeJS = () => {
    const {isDark} = useContext(StyleContext);
    const {navIdiom} = useContext(NavegacionContext)
    return (
        <Fade bottom duration={1000} distance="20px">
        <div className="main">
            <h1 className="skills-heading"><div className="titulo_nodejs">Node.js</div></h1>
            <div className="two-col-backend">
                <div className="twoCol-img-project">
                    <img src={imgWS} alt="WS"/>
                </div>
                <div className="twoCol-info-project">
                    <p
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                    >
                        {navIdiom === 'EN' && 'Node executes Javascript code outside the browser environment, i.e. server-side. NodeJS allows you to create interactive websites, more agile and able to work with other languages such as Phyton. Node is used for the development of APIS, web applications with Ajax, push notifications and, above all, IoT (Internet of Things) applications.'}
                        {navIdiom === 'ES' && 'Node ejecuta el código Javascript fuera del entorno del navegador, es decir, del lado del servidor. NodeJS permite crear sitios web interactivos, más ágiles y capaces de trabajar con otros lenguajes como Phyton. Node es utilizado para la elaboración de APIS, aplicaciones web con Ajax, notificaciones push y, sobre todo, aplicaciones de IoT (Internet de las cosas).'}
                    </p>  
                </div>
            </div>
            <div className="two-col-backend">
                <h2
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                >
                    {navIdiom === 'EN' && 'Node Projects'}
                    {navIdiom === 'ES' && 'Projectos con Node'}
                </h2>
            </div>
            <div className="two-col-backend">
                <div className="twoCol-btn-center">
  
                    <a href="https://github.com/gleenpeltroche/FH-node-rest" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        Rest Server
                    </a>

                    <a href="https://github.com/gleenpeltroche/ClientesGraphQL-Server" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        GraphQL-Server
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/FH-Consola" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Interactive console'}
                        {navIdiom === 'ES' && 'consola interactiva'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/FH-node-clima" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'console API weather'}
                        {navIdiom === 'ES' && 'API clima en Consola'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/FH-webserver-express" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        Express Webserver
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/FH-socket-server-basico" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'basic Sockets'}
                        {navIdiom === 'ES' && 'Sockets básicos'}
                    </a> 
                    
                    <a href="https://github.com/gleenpeltroche/FH-socket-atencion" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        Tickets-sockets
                        
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/FH-socket-chat" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        Chat-sockets
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/MERN-Calendar-Backend" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        MERN Calendar Backend
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/nodesend-servidor" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        NodeSend Backend
                    </a>
                    
                </div>
            </div>         
        </div>
        </Fade>
    )
}

export default NodeJS
