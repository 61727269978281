import React from 'react'
const CustomSVG = () => {
    return (
        <>
        <li className="software-skill-inline">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40"><g transform="matrix(.060 0 0 .060 2 4)"><path d="m413.69 0c0 0-45.37 44.01-94.43 61.759-363.33 131.42-210.1 387.52-205.1 388.36 0 0 20.374-35.48 47.896-55.717 174.64-128.39 210.14-276.17 210.14-276.17 0 0-39.19 177.83-194.56 288.48-34.32 24.426-57.55 84.57-67.39 144.68 0 0 24.325-9.828 34.785-12.49 4.079-26.618 12.607-52.11 27.03-74.875 217.15 25.854 288.27-149.12 297.56-210.14 21.929-144.1-55.928-253.89-55.928-253.89"/></g></svg>
            <p>MongoDB</p>
        </li>
        <li className="software-skill-inline">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="64" height="64"><path d="M32 24.795c-1.164.296-1.884.013-2.53-.957l-4.594-6.356-.664-.88-5.365 7.257c-.613.873-1.256 1.253-2.4.944l6.87-9.222-6.396-8.33c1.1-.214 1.86-.105 2.535.88l4.765 6.435 4.8-6.4c.615-.873 1.276-1.205 2.38-.883l-2.48 3.288-3.36 4.375c-.4.5-.345.842.023 1.325L32 24.795zM.008 15.427l.562-2.764C2.1 7.193 8.37 4.92 12.694 8.3c2.527 1.988 3.155 4.8 3.03 7.95H1.48c-.214 5.67 3.867 9.092 9.07 7.346 1.825-.613 2.9-2.042 3.438-3.83.273-.896.725-1.036 1.567-.78-.43 2.236-1.4 4.104-3.45 5.273-3.063 1.75-7.435 1.184-9.735-1.248C1 21.6.434 19.812.18 17.9c-.04-.316-.12-.617-.18-.92q.008-.776.008-1.552zm1.498-.38h12.872c-.084-4.1-2.637-7.012-6.126-7.037-3.83-.03-6.58 2.813-6.746 7.037z"/></svg>
            <p>Express.js</p>
        </li>
        <li className="software-skill-inline">
            <svg width="207px" height="124px" viewBox="0 0 207 124" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Black-Next.js" transform="translate(-247.000000, -138.000000)">
                        <g id="next-black" transform="translate(247.000000, 138.000000)">
                            <g id="EXT-+-Type-something">
                                <path d="M48.9421964,32.6320058 L87.9011585,32.6320058 L87.9011585,35.7136421 L52.5134345,35.7136421 L52.5134345,58.9070103 L85.7908813,58.9070103 L85.7908813,61.9886466 L52.5134345,61.9886466 L52.5134345,87.4526941 L88.306981,87.4526941 L88.306981,90.5343303 L48.9421964,90.5343303 L48.9421964,32.6320058 Z M91.3912326,32.6320058 L95.5306221,32.6320058 L113.8738,58.0960534 L132.622801,32.6320058 L158.124498,0.286809811 L116.22757,60.7722112 L137.817329,90.5343303 L133.51561,90.5343303 L113.8738,63.4483691 L94.1508254,90.5343303 L89.9302715,90.5343303 L111.682358,60.7722112 L91.3912326,32.6320058 Z M139.359455,35.713642 L139.359455,32.6320058 L183.756439,32.6320058 L183.756439,35.7136421 L163.302983,35.7136421 L163.302983,90.5343303 L159.731745,90.5343303 L159.731745,35.7136421 L139.359455,35.713642 Z" id="EXT"></path>
                                <polygon id="Type-something" points="0.202923647 32.6320058 4.66697141 32.6320058 66.2235778 124.303087 40.785176 90.5343303 3.93649086 37.0111732 3.77416185 90.5343303 0.202923647 90.5343303"></polygon>
                            </g>
                            <path d="M183.396622,86.5227221 C184.134938,86.5227221 184.673474,85.9601075 184.673474,85.233037 C184.673474,84.5059658 184.134938,83.9433513 183.396622,83.9433513 C182.666993,83.9433513 182.11977,84.5059658 182.11977,85.233037 C182.11977,85.9601075 182.666993,86.5227221 183.396622,86.5227221 Z M186.905793,83.1297235 C186.905793,85.2763149 188.460599,86.678523 190.727662,86.678523 C193.142388,86.678523 194.601647,85.233037 194.601647,82.7229099 L194.601647,73.8855335 L192.655968,73.8855335 L192.655968,82.7142542 C192.655968,84.1078073 191.952397,84.8521899 190.710289,84.8521899 C189.598473,84.8521899 188.842785,84.1597409 188.816727,83.1297235 L186.905793,83.1297235 Z M197.146664,83.0172011 C197.285642,85.2503478 199.153145,86.678523 201.932686,86.678523 C204.903321,86.678523 206.762139,85.1811034 206.762139,82.792155 C206.762139,80.9138876 205.702439,79.8752151 203.131364,79.2779777 L201.750279,78.9404092 C200.117298,78.5595622 199.457158,78.0488813 199.457158,77.1573541 C199.457158,76.0321243 200.482113,75.296398 202.019547,75.296398 C203.478806,75.296398 204.48639,76.0148135 204.668797,77.1660091 L206.562359,77.1660091 C206.44944,75.0626962 204.590622,73.5825873 202.045605,73.5825873 C199.309495,73.5825873 197.48542,75.0626962 197.48542,77.2871878 C197.48542,79.1221767 198.519063,80.2127835 200.786126,80.7407758 L202.401734,81.1302779 C204.060773,81.5197807 204.790402,82.091051 204.790402,83.0431676 C204.790402,84.1510859 203.643842,84.9560573 202.08035,84.9560573 C200.403939,84.9560573 199.240006,84.2030196 199.074971,83.0172011 L197.146664,83.0172011 Z" id=".JS"></path>
                        </g>
                    </g>
            </svg>
            <p>Next.js</p>
        </li>
        <li className="software-skill-inline">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
                <path d="M256,0C113.5,0,0.1,113.5,0.1,256S113.5,512,256,512s255.9-113.5,255.9-256S398.5,0,256,0z M55.6,258.2l198.2,198.1
                    C144.7,456.3,55.6,367.3,55.6,258.2z M300.5,451.9L60.1,211.4C80.2,122.4,160.3,55.6,256,55.6c64.2,0.2,124.5,30.7,162.5,82.4
                    l-28.9,24.5C359,117.7,308.1,91,253.8,91.2c-69.4-0.4-131.2,43.5-153.7,109.2l209.3,209.3c51.2-17.9,91.3-62.4,102.4-115.8H325V256
                    h131.3C456.3,351.7,389.5,431.9,300.5,451.9L300.5,451.9z"/>
            </g>
            </svg>
            <p>Gatsby</p>
        </li>
        <li className="software-skill-inline">
            <svg version="1.1" id="GraphQL_Logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 400" >
            <g>
                <g>
                    <g>
                        
                       <rect x="122" y="-0.4" transform="matrix(-0.866 -0.5 0.5 -0.866 163.3196 363.3136)" width="16.6" height="320.3"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="39.8" y="272.2" width="320.3" height="16.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        
                            <rect x="37.9" y="312.2" transform="matrix(-0.866 -0.5 0.5 -0.866 83.0693 663.3409)" width="185" height="16.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        
                            <rect x="177.1" y="71.1" transform="matrix(-0.866 -0.5 0.5 -0.866 463.3409 283.0693)" width="185" height="16.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        
                            <rect x="122.1" y="-13" transform="matrix(-0.5 -0.866 0.866 -0.5 126.7903 232.1221)" width="16.6" height="185"/>
                    </g>
                </g>
                <g>
                    <g>
                        
                            <rect x="109.6" y="151.6" transform="matrix(-0.5 -0.866 0.866 -0.5 266.0828 473.3766)" width="320.3" height="16.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="52.5" y="107.5" width="16.6" height="185"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="330.9" y="107.5" width="16.6" height="185"/>
                    </g>
                </g>
                <g>
                    <g>
                        
                            <rect x="262.4" y="240.1" transform="matrix(-0.5 -0.866 0.866 -0.5 126.7953 714.2875)" width="14.5" height="160.9"/>
                    </g>
                </g>
                <path d="M369.5,297.9c-9.6,16.7-31,22.4-47.7,12.8c-16.7-9.6-22.4-31-12.8-47.7c9.6-16.7,31-22.4,47.7-12.8
                    C373.5,259.9,379.2,281.2,369.5,297.9"/>
                <path d="M90.9,137c-9.6,16.7-31,22.4-47.7,12.8c-16.7-9.6-22.4-31-12.8-47.7c9.6-16.7,31-22.4,47.7-12.8
                    C94.8,99,100.5,120.3,90.9,137"/>
                <path d="M30.5,297.9c-9.6-16.7-3.9-38,12.8-47.7c16.7-9.6,38-3.9,47.7,12.8c9.6,16.7,3.9,38-12.8,47.7
                    C61.4,320.3,40.1,314.6,30.5,297.9"/>
                <path d="M309.1,137c-9.6-16.7-3.9-38,12.8-47.7c16.7-9.6,38-3.9,47.7,12.8c9.6,16.7,3.9,38-12.8,47.7
                    C340.1,159.4,318.7,153.7,309.1,137"/>
                <path d="M200,395.8c-19.3,0-34.9-15.6-34.9-34.9c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9
                    C234.9,380.1,219.3,395.8,200,395.8"/>
                <path d="M200,74c-19.3,0-34.9-15.6-34.9-34.9c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9
                    C234.9,58.4,219.3,74,200,74"/>
            </g>
            </svg>
            <p>GraphQL</p>
        </li>
        <li className="software-skill-inline">
            <svg height="2430" viewBox="26.69 28.9 1173.9 1144.1" width="2500" xmlns="http://www.w3.org/2000/svg"><path d="m599.49 29.7c-2.2.1-10.5.8-18.5 1.4-100 7.4-201.4 44.4-284.5 103.8-27.5 19.6-49.2 38-73.4 62.2-139.1 139.2-196.4 338.4-152.9 531.9 17.8 79.4 55.8 159.3 106.2 223.5 79.1 100.9 187.5 172 309.1 202.8 49.5 12.6 90.6 17.7 142.5 17.7 51.2 0 88.9-4.6 137.7-16.5 70.1-17.2 131.7-45.5 193.3-88.6 59-41.4 113.9-99.4 153.3-161.9 50.8-80.8 79.7-170 86.8-267.9 1.5-20.6.6-71.6-1.6-92.6-4.4-41.7-15.6-96.7-22.1-108.2-2.6-4.5-9.9-10.3-15.3-12.2-1.9-.6-6.1-1.1-9.5-1.1-15 0-27.6 12.5-27.6 27.2 0 3.5 1.6 11.9 3.9 21 19.8 77 21.4 161.6 4.5 238.8-21.6 98.9-69.8 186.1-142.5 257.6-33.9 33.3-66.6 57.9-108.9 82.1-98.7 56.5-221.4 77.9-336.5 58.7-87.4-14.5-163.3-48.1-235.4-104.2-33.9-26.4-75.2-69.9-100.8-106.3-44.2-62.8-72-129-86.4-205.9-7.3-39.2-9.8-91.2-6.4-133.5 5.2-64.2 21.3-123.8 48.6-180.3 12.6-26.3 20.4-40 35.5-62.7 30.8-46.6 71.6-90.2 116.7-124.8 52.9-40.7 115.7-71.6 179.2-88.2 34.3-9 61.7-13.5 96.5-16 125.9-9 248.3 27.2 348 103.1 7.4 5.6 15.2 11.7 17.3 13.6l3.8 3.3-1.5 4.2c-7.7 21.6-2 48.5 14.2 67.3 8.3 9.6 23.3 18.6 36 21.6 8.7 2.1 23.8 2.1 32.6 0 22.3-5.3 43.1-24.4 49.7-45.6 7.4-23.7 3.1-47.1-12.1-66.1-16.8-21-45.7-30.4-72.4-23.4l-5.8 1.5-8.2-6.8c-33.3-27.9-73.6-53.7-116-74.2-68.8-33.3-137.9-51.2-216.6-56-15.1-.9-50.8-1.1-60.5-.3z"/><path d="m540.29 358.7c-10.6 27.4-33.6 87.4-51.3 133.3s-54 140.2-80.7 209.5c-26.6 69.3-48.7 126.3-48.9 126.7-.3.5 27.8.8 62.3.8h62.9l3.7-10.3c2.1-5.6 9.5-25.4 16.4-44l12.6-33.7h94.4c75.2 0 94.4-.3 94.1-1.3-1-2.7-16.4-46.7-24.6-70.2l-8.8-25-62.5-.5-62.4-.5 40.6-111.9c24.4-67.2 40.9-111.2 41.2-110.2.4.9 16.6 47 36.1 102.4s51.7 146.6 71.5 202.7l35.9 102 62.6.3c34.4.1 62.6 0 62.6-.2s-8.1-21.5-18.1-47.2c-10-25.8-31.4-81.3-47.6-123.4s-34.4-89.3-40.5-105c-6-15.7-16.5-42.9-23.3-60.5s-24.5-63.7-39.5-102.5-28.1-72.9-29.2-75.8l-1.9-5.2h-138.5z"/></svg>
            <p>Apollo</p>
        </li>
        <li className="software-skill-inline">
            <svg width="1024" height="1024" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)"/>
            </svg>
            <p>Github</p>
        </li>
        <li className="software-skill-inline">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 512 512">
                <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>
                <path d="M213.102,181.6h-72.24V352h33.12v-57.12h40.32c29.531,0,53.04-25.713,53.04-56.64
                    C267.342,212,246.166,181.6,213.102,181.6z M212.382,265.84h-38.4v-55.2h36.96c14.057,0,22.8,13.2,22.8,27.6
                    C233.742,255,224,265.84,212.382,265.84z"/>
                <path d="M336.939,276.64c-4.642-1.118-24.72-4.39-24.72-15.84c0-9.175,7.531-14.16,19.2-14.16c15.831,0,30.079,9.12,36.48,13.92
                    l12.96-19.44c-6.881-6.079-21.609-17.28-49.2-17.28c-26.284,0-49.44,14.91-49.44,41.52c0,27.64,36.72,32.801,44.88,35.04
                    c9.757,2.723,27.6,6.225,27.6,17.04c0,8.81-8.449,13.92-20.64,13.92c-18.809,0-36.48-11.119-44.16-16.56l-12,20.16
                    c7.2,6.24,27.851,19.44,56.88,19.44c15.679,0,50.88-4.9,50.88-39.84C385.659,285,345.579,278.882,336.939,276.64z"/>
            </svg>
            <p>Photoshop</p>
        </li>
        </>
    )
}

export default CustomSVG
