import React from 'react'
import './reactlogo.css'

const ReactLogo = () => {
    return (
        <div className="react-logo-text">
            <span className="react-logo">
                <span className="nucleo"></span>
            </span>   
        </div>    
    )
}

export default ReactLogo
