import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'
import IngSVG from './IngSVG'

const IngSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Engineering technologies'}
                {navIdiom === 'ES' && 'tecnologías de Ingeniería'}
            </h2>
            <IngSVG />  
        </>
    )
}

export default IngSkills
