import React, { useContext } from "react";
import "./Education.css";
import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo, educationInfoES} from "../../portfolio";
import { NavegacionContext } from "../../context/NavegacionProvider";

export default function Education() {
  
  const {navIdiom} = useContext(NavegacionContext);

  if (educationInfo.display) {
    return (
      <div className="education-section" id="education">
        <h1 className="education-heading">
          {navIdiom === 'EN' && 'Education'}
          {navIdiom === 'ES' && 'Educación'}
        </h1>
        {navIdiom === 'EN' && 
        (<div className="education-card-container">
          {educationInfo.schools.map((school, i) => (
            <EducationCard
              key={i}
              school={school} 
            />
          ))}
        </div>)}
        {navIdiom === 'ES' && 
        (<div className="education-card-container">
          {educationInfoES.schools.map((school, i) => (
            <EducationCard
              key={i}
              school={school} 
            />
          ))}
        </div>)}
      </div>
    );
  }
  return null;
}
