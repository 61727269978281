import React, { useContext } from 'react'
// import { NavegacionContext } from '../../context/NavegacionProvider';
import { 
  // useHistory,
  NavLink } from "react-router-dom";
import { NavegacionContext } from '../../context/NavegacionProvider';

export const NavInicio = (
  // viewSkills, viewExperience, viewOpenSource, viewAchievement, viewBlog, viewTalks
  ) => {
    const {navIdiom} = useContext(NavegacionContext);
  // const {setNavurl} = useContext(NavegacionContext);
  // const history = useHistory();

  // const Setcertificates = (e) => {
  //         e.preventDefault();
  //         history.push('/certificates');
  //         setNavurl('/certificates');
  //       }
  return (
  <>
  {/* {viewSkills && (
      <li>
        <a href="#skills">Skills</a>
      </li>
    )}
    {viewExperience && (
      <li>
        <a href="#education">Education</a>
      </li>
    )}
    {viewOpenSource && (
      <li>
        <a href="#idioms">Idioms</a>
      </li>
    )}
    {viewAchievement && (
      <li>
        <a href="#achievements">Achievements</a>
      </li>
    )}
    {viewBlog && (
      <li>
        <a href="#blogs">Blogs</a>
      </li>
    )}
    {viewTalks && (
      <li>
        <a href="#talks">Talks</a>
      </li>
    )} */}
    <li>
      <NavLink 
        exact to="/"
        activeStyle={{
          borderBottom: "4px solid #cccccc"
        }}
        
      >
        {navIdiom ==='EN' && 'Home'}
        {navIdiom ==='ES' && 'Inicio'}
      </NavLink>
    </li>
    <li>
      <NavLink 
        exact to="/skills"
        activeStyle={{
          borderBottom: "4px solid #cccccc"
        }}
      >
        {navIdiom ==='EN' && 'Skills'}
        {navIdiom ==='ES' && 'Habilidades'}
      </NavLink>
    </li>
    <li>
      <NavLink 
        exact to="/achievements"
        activeStyle={{
          borderBottom: "4px solid #cccccc"
        }}
      >
        {navIdiom ==='EN' && 'Achievements'}
        {navIdiom ==='ES' && 'Logros'}
      </NavLink>
    </li>
    <li>
      <NavLink 
        exact to="/projects"
        activeStyle={{
          borderBottom: "4px solid #cccccc"
        }}
      >
        {navIdiom ==='EN' && 'Projects'}
        {navIdiom ==='ES' && 'Proyectos'}
      </NavLink>
    </li>
    <li>
      <NavLink 
        exact to="/Contact"
        activeStyle={{
          borderBottom: "4px solid #cccccc"
        }}
      >
        {navIdiom ==='EN' && 'Contact'}
        {navIdiom ==='ES' && 'Contacto'}
      </NavLink>
    </li>
  </>    
  )
}

export default NavInicio
