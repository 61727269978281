import React, {useContext} from "react";
import "./StartupProjects.css";
import {bigProjects} from "../../portfolio";
import {bigProjectsES} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { NavLink } from "react-router-dom";
import { NavegacionContext } from "../../context/NavegacionProvider";


export default function StartupProject() {
  
  const {navIdiom} = useContext(NavegacionContext);

  const {isDark} = useContext(StyleContext);
  if (!bigProjects.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">
            {navIdiom ==='EN' && bigProjects.title}
            {navIdiom ==='ES' && bigProjectsES.title}
          </h1>
          <p
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {navIdiom ==='EN' && bigProjects.subtitle}
            {navIdiom ==='ES' && bigProjectsES.subtitle}
          </p>

          <div className="projects-container">
            {navIdiom ==='EN' && bigProjects.projects.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.image ? (
                    <div className="project-image">
                      <img
                        src={project.image}
                        alt={project.projectName}
                        className="card-image"
                      ></img>
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.projectDesc}
                    </p>
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <NavLink 
                              to={`/${link.url}`}
                              style={{color: 'white', textDecoration: 'none'}}
                              key={i}
                            >
                              <span
                                className={
                                  isDark ? "dark-mode project-tag" : "project-tag"
                                }
                              >
                                  {link.name}
                              </span>
                            </NavLink>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
            
            
            {navIdiom ==='ES' && bigProjectsES.projects.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.image ? (
                    <div className="project-image">
                      <img
                        src={project.image}
                        alt={project.projectName}
                        className="card-image"
                      ></img>
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.projectDesc}
                    </p>
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <NavLink 
                              to={`/${link.url}`}
                              style={{color: 'white', textDecoration: 'none'}}
                              key={i}
                            >
                              <span
                                className={
                                  isDark ? "dark-mode project-tag" : "project-tag"
                                }
                              >
                                {link.name}
                              </span>
                            </NavLink>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
