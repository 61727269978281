import React, {useContext} from "react";
import "./ProjectsPortfolio.css";
// import emoji from "react-easy-emoji";
import {Fade} from "react-reveal";
import StyleContext from "../../../contexts/StyleContext";
import VanillaJS from "./VanillaJS";
import ReactJS from "./ReactJS";
import NextJS from "./NextJS";
import ScrollToTop from "../../ScrollToTop";
import { NavegacionContext } from "../../../context/NavegacionProvider";
import Angular from "./Angular";


export default function ProjectsPortfolio() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext)
  return (
    <>
    <ScrollToTop/>

    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading"><div className="titulo_angular">Angular</div></h1>
          
          <p
            className={
              isDark
                ? "dark-mode"
                : "subTitle"
            }
          >
            {navIdiom === 'EN' && 'Angular is an MVC (Model View Controller) framework for Front End Web Development, developed in Typescript, open source and maintained by Google, which allows you to create SPA applications (Single-Page Applications).'}
            {navIdiom === 'ES' && 'Angular es un framework MVC (Modelo Vista Controlador) para el Desarrollo Web Front End, desarrollado en Typescript, de código abierto y mantenido por Google, que permite crear aplicaciones SPA (Single-Page Applications).'}
            
          </p>
        </div>
        <main className="page-content">
          <Angular />
        </main>
      </div>
    </Fade>
    
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading"><div className="titulo_next">Next.js</div></h1>
          
          <p
            className={
              isDark
                ? "dark-mode"
                : "subTitle"
            }
          >
            {navIdiom === 'EN' && 'Framework built on React that enables functionality such as server-side rendering and generating static websites. Next allows developers to quickly create static and dynamic JAMstack websites and is used widely by many large companies.'}
            {navIdiom === 'ES' && 'Framework construido sobre React el cual nos permite funcionalidades como el server-side rendering y la generación de sitios web estáticos. Next permite a los desarrolladores crear rápidamente sitios web estáticos y dinámicos con el JAMstack y es utilizado ampliamente por muchas grandes empresas.'}
          </p>
        </div>
        <main className="page-content">
          <NextJS />
        </main>
      </div>
    </Fade>

    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading"><div className="titulo_react">React</div></h1>
          
          <p
            className={
              isDark
                ? "dark-mode"
                : "subTitle"
            }
          >
            {navIdiom === 'EN' && 'It allows developing web applications in a more orderly way and with less code than using pure Javascript (vanilla) or libraries such as jQuery focused on DOM manipulation. It allows views to be associated with data, so that if the data changes, so do the views.'}
            {navIdiom === 'ES' && 'Permite desarrollar aplicaciones web de una manera más ordenada y con menos código que usando Javascript puro (vanilla) o librerías como jQuery centradas en la manipulación del DOM. Permite que las vistas se asocien con los datos, de modo que si cambian los datos, también cambian las vistas.'}
            
          </p>
        </div>
        <main className="page-content">
          <ReactJS />
        </main>
      </div>
    </Fade>

    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading"><div className="titulo_vanilla">Vanilla JS</div></h1>
          <p
            className={
              isDark
                ? "dark-mode"
                : "subTitle"
            }
          >
            {navIdiom === 'EN' && 'Name to refer to using JavaScript without any additional libraries like jQuery, React, Angular, Vue, Svelte, etc. People use it to demostrate other developers the things can be done nowadays without the need for additional JavaScript libraries.'}
            {navIdiom === 'ES' && 'Nombre para referirse al uso de JavaScript sin librerías adicionales como jQuery, React, Angular, Vue, Svelte, etc. La gente lo utiliza para demostrar a otros desarrolladores las cosas que se pueden hacer hoy en día sin necesidad de bibliotecas JavaScript adicionales.'}
          </p>
        </div>
        <main className="page-content">
          <VanillaJS />
        </main>
      </div>
    </Fade>

  </>
  );
}
