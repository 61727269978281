import React, {useContext}  from 'react'
import imgWS from '../../../../assets/images/projects/Python/webscraping.png'
import StyleContext from "../../../../contexts/StyleContext";
import {Fade} from "react-reveal";
import { NavegacionContext } from '../../../../context/NavegacionProvider';

const WebScrapingProjects = () => {
    const {isDark} = useContext(StyleContext);
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <Fade bottom duration={1000} distance="20px">
        <div className="main">
            <h1 className="skills-heading"><div className="titulo_nodejs">Web Scraping</div></h1>
            <div className="two-col-backend">
                <div className="twoCol-img-project">
                    <img src={imgWS} alt="WS"/>
                </div>
                <div className="twoCol-info-project">
                    <p
                        className={
                        isDark
                            ? "dark-mode"
                            : "subTitle"
                        }
                    >
                        {navIdiom === 'EN' && 'The process of extracting and storing data from web pages, manually or automatically, for analysis or use. This data can be stored in databases or tables. Python is one of the most used programming languages for Web Scraping. The libraries I use for Web Scraping are LXML, Requests, Scrapy, Selenium and Beautiful Soup.'}
                        {navIdiom === 'ES' && 'Proceso de extracción y almacenamiento datos de páginas web, de forma manual o automática, para su analisis o utilización. Estos datos pueden ser almacenados en bases de datos o tablas. Python es uno de los lenguajes de programación más usados a la hora de hacer Web Scraping. Las librerías que utilizo para realizar Web Scraping son LXML, Requests, Scrapy, Selenium y Beautiful Soup.'}
                    </p>  
                </div>
            </div>
            <div className="two-col-backend">
                <h2
                    className={
                    isDark
                        ? "dark-mode"
                        : "subTitle"
                    }
                >
                    {navIdiom === 'EN' && 'WS Projects with Python'}
                    {navIdiom === 'ES' && 'Proyectos de WS con Python'}
                </h2>
            </div>
            <div className="two-col-backend">
                <div className="twoCol-btn-center">
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS1-StaticPages" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Static Pages'}
                        {navIdiom === 'ES' && 'Páginas Estáticas'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS2-MultiPagesSameDomain" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Pages with Same Domain'}
                        {navIdiom === 'ES' && 'páginas con el Mismo dominio'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS3-DinamicPages" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Dinamic Pages'}
                        {navIdiom === 'ES' && 'Páginas Dinámicas'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS4-APIs-iframes" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        APIs y iFrames
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS5-Auth-Captcha" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'authentication and Captchas'}
                        {navIdiom === 'ES' && 'Autenticación y Captchas'}
                    </a>
                    
                    <a href="https://github.com/gleenpeltroche/Python-WebScraping/tree/master/WS6-SaveOnDataBase" target="_blank" rel="noopener noreferrer" className="btn-twoCol-info">
                        {navIdiom === 'EN' && 'Automated data storage'}
                        {navIdiom === 'ES' && 'Almacenamiento automátizado de datos'}
                    </a>
                        
                </div>
            </div>         
        </div>
        </Fade>
    )
}

export default WebScrapingProjects