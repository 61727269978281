import React from 'react'

const CutomIngSVG = () => {
    return (
        <>
        <li className="software-skill-inline">
            <svg xmlns="http://www.w3.org/2000/svg" width="45pt" height="45pt" viewBox="0 0 45 45" version="1.1">
            <g id="surface1">
            <path d="M 43.582031 32.308594 C 41.035156 25.878906 38.917969 19.28125 36.484375 12.804688 C 35.410156 10.050781 34.359375 7.269531 32.894531 4.691406 C 32.3125 3.765625 31.753906 2.746094 30.800781 2.15625 C 30.558594 2.003906 30.289062 1.9375 30.019531 1.941406 C 29.574219 1.949219 29.128906 2.152344 28.789062 2.4375 C 27.507812 3.453125 26.75 4.929688 25.898438 6.289062 C 24.390625 8.808594 22.808594 11.453125 20.292969 13.089844 C 19.109375 13.925781 17.5625 14.015625 16.425781 14.910156 C 14.867188 16.082031 13.808594 17.75 12.53125 19.191406 C 12.242188 19.539062 11.789062 19.679688 11.394531 19.867188 C 7.585938 21.417969 3.773438 22.96875 0 24.601562 C 3.195312 27.074219 6.601562 29.273438 9.863281 31.660156 C 10.757812 31.484375 11.652344 31.117188 12.574219 31.28125 C 14.039062 31.738281 14.894531 33.140625 15.566406 34.417969 C 16.925781 37.210938 17.910156 40.160156 19.015625 43.058594 C 20.859375 42.925781 22.519531 41.976562 23.945312 40.859375 C 26.691406 38.632812 28.78125 35.761719 31.085938 33.105469 C 32.1875 31.945312 33.3125 30.566406 34.96875 30.242188 C 36.644531 29.839844 38.4375 30.4375 39.679688 31.589844 C 41.421875 33.148438 42.964844 34.949219 45 36.140625 C 44.726562 34.792969 44.070312 33.582031 43.582031 32.308594 Z M 15.101562 27.777344 C 13.496094 28.726562 11.789062 29.511719 10.121094 30.339844 C 7.496094 28.585938 4.945312 26.722656 2.367188 24.902344 C 5.839844 23.382812 9.367188 21.96875 12.886719 20.550781 C 14.554688 21.851562 16.273438 23.085938 17.941406 24.390625 C 17.15625 25.640625 16.214844 26.804688 15.101562 27.777344 Z M 18.675781 23.414062 C 17.019531 22.167969 15.34375 20.957031 13.710938 19.679688 C 14.757812 18.253906 15.902344 16.875 17.277344 15.75 C 18.183594 15.167969 19.292969 15.007812 20.238281 14.484375 C 22.476562 13.367188 24.003906 11.316406 25.460938 9.355469 C 23.59375 14.210938 21.597656 19.082031 18.675781 23.414062 Z M 18.675781 23.414062 "/>
            </g>
            </svg>
            <p>MatLab</p>
        </li>
        <li className="software-skill-inline">
            <svg xmlns="http://www.w3.org/2000/svg" width="46pt" height="45pt" viewBox="0 0 46 45" version="1.1">
            <g id="surface1">
            <path  d="M 23.09375 0.046875 C 21.785156 0.109375 20.210938 0.296875 18.929688 0.550781 C 17.585938 0.8125 16.199219 1.308594 15.902344 1.628906 C 15.75 1.792969 15.753906 2.007812 15.910156 2.164062 C 16.136719 2.394531 16.363281 2.417969 18.644531 2.460938 C 21.695312 2.523438 23.328125 2.625 25.210938 2.863281 C 27.351562 3.140625 28.398438 3.464844 29.027344 4.054688 C 29.855469 4.828125 29.777344 5.804688 28.742188 7.636719 C 27.832031 9.238281 26.710938 10.644531 24.839844 12.523438 C 23.121094 14.246094 20.972656 16.101562 18.769531 17.761719 C 17.460938 18.746094 17.375 18.8125 17.363281 18.929688 C 17.339844 19.089844 17.527344 19.144531 17.832031 19.054688 C 18.558594 18.835938 20.644531 17.921875 22.136719 17.167969 C 26.96875 14.726562 30.640625 11.632812 32.542969 8.414062 C 33.613281 6.605469 34.070312 4.851562 33.769531 3.675781 C 33.515625 2.679688 32.847656 1.925781 31.703125 1.347656 C 30.359375 0.667969 27.964844 0.175781 25.421875 0.046875 C 24.375 0 24.222656 0 23.09375 0.046875 Z M 23.09375 0.046875 "/>
            <path  d="M 36.113281 15.371094 C 34.890625 15.472656 34.5625 15.515625 33.722656 15.675781 C 31.066406 16.191406 29.054688 17.472656 28.609375 18.929688 C 28.261719 20.058594 28.640625 21.472656 29.734375 23.167969 C 30.335938 24.105469 30.949219 24.882812 32.433594 26.585938 C 35.519531 30.132812 37.523438 32.761719 38.167969 34.085938 C 38.378906 34.527344 38.386719 34.554688 38.386719 34.96875 C 38.386719 35.339844 38.371094 35.425781 38.253906 35.667969 C 37.957031 36.277344 37.3125 36.5625 35.546875 36.855469 C 33.359375 37.21875 30.921875 37.382812 26.289062 37.453125 C 22.839844 37.503906 22.230469 37.539062 21.925781 37.6875 C 21.683594 37.808594 21.589844 38.007812 21.683594 38.207031 C 21.863281 38.59375 23.160156 39.078125 24.675781 39.324219 C 26.09375 39.550781 27.957031 39.769531 29.851562 39.914062 C 30.894531 40 33.609375 40.027344 34.589844 39.96875 C 37.101562 39.804688 38.875 39.394531 40.429688 38.613281 C 41.816406 37.910156 42.625 37.054688 42.890625 36.007812 C 42.96875 35.683594 42.984375 34.917969 42.910156 34.554688 C 42.734375 33.667969 42.207031 32.550781 41.394531 31.332031 C 40.4375 29.886719 39.441406 28.566406 36.414062 24.710938 C 34.484375 22.261719 33.839844 21.421875 33.515625 20.960938 C 32.949219 20.15625 32.925781 19.578125 33.441406 19.109375 C 34.378906 18.257812 37.992188 17.761719 43.242188 17.753906 C 45.480469 17.753906 46.007812 17.648438 46.007812 17.1875 C 46.007812 16.875 45.1875 16.355469 44.320312 16.125 C 43.355469 15.867188 41.765625 15.609375 40.21875 15.460938 C 39.472656 15.382812 36.636719 15.320312 36.113281 15.371094 Z M 36.113281 15.371094 "/>
            <path  d="M 13.976562 21.386719 C 11.058594 21.503906 7.332031 22.21875 6.085938 22.902344 C 5.835938 23.039062 5.585938 23.300781 5.578125 23.429688 C 5.558594 23.691406 5.613281 23.78125 5.859375 23.910156 C 6.191406 24.078125 6.613281 24.101562 9.625 24.101562 C 11.234375 24.101562 12.949219 24.125 13.4375 24.148438 C 17.015625 24.335938 19.304688 24.867188 20.246094 25.734375 C 20.773438 26.210938 20.964844 26.652344 20.964844 27.386719 C 20.964844 28.15625 20.695312 29.003906 20.0625 30.269531 C 18.554688 33.265625 15.664062 36.089844 11.5625 38.574219 C 10.167969 39.417969 8.128906 40.496094 7.472656 40.734375 C 7.074219 40.878906 6.582031 40.957031 6.320312 40.910156 C 5.8125 40.8125 5.613281 40.359375 5.796875 39.71875 C 6.015625 38.964844 7.886719 35.429688 10.898438 30.09375 C 11.457031 29.105469 11.960938 28.175781 12.011719 28.035156 C 12.140625 27.6875 12.15625 27.246094 12.039062 27.070312 C 11.992188 27 11.882812 26.902344 11.796875 26.859375 C 11.660156 26.792969 11.601562 26.789062 11.433594 26.84375 C 10.171875 27.210938 7.753906 30.554688 4.117188 36.957031 C 2.304688 40.148438 0.046875 44.421875 0.046875 44.660156 C 0.0507812 45.023438 0.46875 45.039062 1.945312 44.71875 C 5.285156 43.996094 9.976562 42.160156 13.53125 40.175781 C 18.929688 37.167969 22.5625 33.746094 24.5 29.835938 C 25.308594 28.214844 25.660156 27.054688 25.664062 26.054688 C 25.664062 25.464844 25.597656 25.125 25.402344 24.691406 C 25.078125 23.980469 24.523438 23.484375 23.472656 22.964844 C 21.207031 21.832031 17.640625 21.242188 13.976562 21.386719 Z M 13.976562 21.386719 "/>
            </g>
            </svg>
            <p>SolidWorks</p>
        </li>
        <li className="software-skill-inline">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M43.911,12.003L43.911,12.003L27.069,2.54c-1.276-0.717-2.861-0.717-4.138,0L6.089,12.003C4.801,12.727,4,14.081,4,15.537 v18.927c0,1.455,0.8,2.809,2.089,3.533l16.842,9.464c0.638,0.358,1.354,0.538,2.069,0.538s1.431-0.179,2.069-0.538l16.842-9.463 C45.2,37.272,46,35.918,46,34.463V15.537C46,14.081,45.199,12.727,43.911,12.003z M25,37c-6.617,0-12-5.383-12-12s5.383-12,12-12 c3.78,0,7.275,1.755,9.542,4.741l-4.38,2.536C28.844,18.837,26.973,18,25,18c-3.86,0-7,3.14-7,7s3.14,7,7,7 c1.973,0,3.844-0.837,5.162-2.277l4.38,2.536C32.275,35.245,28.78,37,25,37z M37,26h-2v2h-2v-2h-2v-2h2v-2h2v2h2V26z M44,26h-2v2h-2 v-2h-2v-2h2v-2h2v2h2V26z"/>
            </svg>
            <p>C++</p>
        </li>
        </>
    )
}

export default CutomIngSVG