import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'
import FrontedSVG from './FrontedSVG'

const FrontedSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Frontend Technologies'}
                {navIdiom === 'ES' && 'Tecnologías Frontend'}
            </h2>
            <FrontedSVG />  
        </>
    )
}

export default FrontedSkills
