import React, {useContext} from 'react'
import { NavegacionContext } from '../../../../context/NavegacionProvider';
import SmallProjectsCard from "../smallProjects/SmallProjectsCard";
// import emoji from "react-easy-emoji";
import './reactnative.css';


const ReactNative = () => {

    
    return (
        <>
            <SmallProjectsCard 
                title= {'BirthdayApp'}
                elements = {['Native App','Hooks', 'Firebase']}
                url = {'https://github.com/gleenpeltroche/RN-BirthdayApp'}
                type = 'react-native'
                buttontext= {['Show Code', 'Ver Código']}
            />
            <SmallProjectsCard 
                title= {'Cotizador'}
                elements = {['Native App','Hooks']}
                url = {'https://github.com/gleenpeltroche/RN-cotizador'}
                type = 'react-native'
                buttontext={['Show Code', 'Ver Código']}
            />
            {/* <SmallProjectsCard 
                title= {'Cotizador'}
                elements = {['Native App','Hooks']}
                url = {'https://next-product-hunt-n1rfwgxwx-gleenpeltroche.vercel.app/'}
                type = 'react-native'
            />
            <SmallProjectsCard 
                title= {'Cotizador'}
                elements = {['Native App','Hooks']}
                url = {'https://next-product-hunt-n1rfwgxwx-gleenpeltroche.vercel.app/'}
                type = 'react-native'
            />
            <SmallProjectsCard 
                title= {emoji("⭐ Citas ⭐")}
                elements = {['Native App','Hooks']}
                url = {'https://next-product-hunt-n1rfwgxwx-gleenpeltroche.vercel.app/'}
                type = 'react-native'
            /> */}
        </>
    )
}

export default ReactNative

