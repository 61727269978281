import React, {useContext} from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import {illustration, skillsSection, skillsSectionES} from "../../portfolio";
import {Fade} from "react-reveal";
import webdevelopment from "../../assets/lottie/webdevelopment.svg";
// import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";
import { NavegacionContext } from "../../context/NavegacionProvider";

export default function Skills() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext)
  if (!skillsSection.display) {
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <img src={webdevelopment} alt="SVG webdevelopment" />    
              // <DisplayLottie animationData={webdevelopment} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/developerActivity.svg")}
              ></img>
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1
              className={isDark ? "dark-mode skills-heading" : "skills-heading"}
            >
              {navIdiom === 'EN' && skillsSection.title}
              {navIdiom === 'ES' && skillsSectionES.title}
              {" "}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle skills-text-subtitle"
                  : "subTitle skills-text-subtitle"
              }
            >
              {navIdiom === 'EN' && skillsSection.subTitle}
              {navIdiom === 'ES' && skillsSectionES.subTitle}
            </p>
            <SoftwareSkill />
            {navIdiom === 'EN' && 
            (<div>
              {skillsSection.skills.map((skills, i) => {
                return (
                  <p
                    key={i}
                    className={
                      isDark
                        ? "dark-mode subTitle skills-text"
                        : "subTitle skills-text"
                    }
                  >
                    {skills}
                  </p>
                );
              })}
            </div>)}
            {navIdiom === 'ES' && 
            (<div>
              {skillsSectionES.skills.map((skills, i) => {
                return (
                  <p
                    key={i}
                    className={
                      isDark
                        ? "dark-mode subTitle skills-text"
                        : "subTitle skills-text"
                    }
                  >
                    {skills}
                  </p>
                );
              })}
            </div>)}
          </div>
        </Fade>
      </div>
    </div>
  );
}
