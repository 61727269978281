import React, {useContext} from "react";
import Headroom from "react-headroom";
import "./Header.css";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  // workExperiences,
  // skillsSection,
  // openSource,
  // blogSection,
  // talkSection,
  // achievementSection
} from "../../portfolio";
import NavInicio from "./NavInicio";
import {Link} from "react-router-dom";

function Header() {
  const {isDark} = useContext(StyleContext);
  // const viewExperience = workExperiences.display;
  // const viewOpenSource = openSource.display;
  // const viewSkills = skillsSection.display;
  // const viewAchievement = achievementSection.display;
  // const viewBlog = blogSection.display;
  // const viewTalks = talkSection.display;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <Link to="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </Link>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          <NavInicio 
          // viewSkills={viewSkills}
          // viewExperience={viewExperience}
          // viewOpenSource={viewOpenSource}
          // viewAchievement={viewAchievement}
          // viewBlog ={viewBlog}
          // viewTalks={viewTalks}
          />
          <li>
            <a>
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
