import React from "react";
import "../ProjectsPortfolio.css";
// import emoji from "react-easy-emoji";
import WebScrapingProjects from "./WebScrapingProjects";
// import MLProjects from "./MLProjects";
import DLProjects from "./DLProjects";
import ScrollToTop from "../../../ScrollToTop";


export default function AIProjets() {
  return (
    <>
      <ScrollToTop/>
      <WebScrapingProjects />
      {/* <MLProjects /> */}
      <DLProjects />
    </>
  );
}
