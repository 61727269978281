import React from "react";
import "../ProjectsPortfolio.css";
// import emoji from "react-easy-emoji";
import NodeJS from "./NodeJS";
import ScrollToTop from "../../../ScrollToTop";


export default function BackendProjects() {
  return (
    <>
    <ScrollToTop/>
    <NodeJS />
  </>
  );
}
