import React, { useContext } from 'react'
import { NavegacionContext } from '../../../context/NavegacionProvider'
import emoji from "react-easy-emoji";
import '../SkillsGeneral.css'

const IdiomSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <div className="idioms-container">
            {navIdiom === 'EN' && (
                <div>
                    <p>Upper Intermediate English Level (B2) {emoji("🇺🇸")}</p>
                    <p>Native Spanish {emoji("🇵🇪")}</p>
                </div>
            )}
            {navIdiom === 'ES' && (
                <div>
                    <p>Nivel de Ingles Intermedio Superior (B2) {emoji("🇺🇸")}</p>
                    <p>Español Nativo {emoji("🇵🇪")}</p>
                </div>
            )}    
        </div>
    )
}

export default IdiomSkills