import React, { useContext } from 'react';
import { NavegacionContext } from '../../../context/NavegacionProvider';
import CloudSVG from './CloudSVG';

const CloudSkill = () => {
    
    const {navIdiom} = useContext(NavegacionContext);

    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Cloud Technologies'}
                {navIdiom === 'ES' && 'Tecnologías de nube'}
            </h2>
            <CloudSVG />
        </>
    )
}

export default CloudSkill
