import React, {useContext} from "react";
import "./SkillsGeneral.css";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import SoftSkill from "./skillsComponents/SoftSkill";
import HardSkills from "./skillsComponents/HardSkills";
import FrontedSkills from "./skillsComponents/FrontedSkills";
import BackendSkills from "./skillsComponents/BackendSkills";
import IngSkills from "./skillsComponents/IngSkills";
import DesingSkills from "./skillsComponents/DesingSkills";
import CloudSkill from "./skillsComponents/CloudSkill";
import { NavegacionContext } from "../../context/NavegacionProvider";
import IdiomSkills from "./skillsComponents/IdiomSkills";
import AppSkills from "./skillsComponents/AppSkills";
export default function SkillsGeneral() {
  const {isDark} = useContext(StyleContext);
  const {navIdiom} = useContext(NavegacionContext)
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main">
        <div className="skills-main-div">
          <div className="skills-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading skills-heading"
                  : "heading skills-heading"
              }
            > 
              {navIdiom === 'EN' && 'My Professional Skills'}
              {navIdiom === 'ES' && 'Mis Habilidades Profesionales'}
              {emoji("⭐")}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle skills-subtitle"
                  : "subTitle skills-subtitle"
              }
            >
              {navIdiom === 'EN' && 'Skills that I possess and that I acquired during my university life.'}
              {navIdiom === 'ES' && 'Habilidades que poseo y que adquirí durante mi vida universitaria.'}
            </p>
          </div>
          <IdiomSkills />
        </div>
        <SoftSkill />
        <HardSkills />
        <IngSkills />
        <FrontedSkills />
        <AppSkills />
        <BackendSkills />
        <CloudSkill />
        <DesingSkills />
      </div>
    </Fade>
  );
}
