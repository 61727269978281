import React from 'react'
import SmallProjectsCard from "./smallProjects/SmallProjectsCard";
// import emoji from "react-easy-emoji";


const Angular = () => {
    return (
        <>
            <SmallProjectsCard 
                title= {'AngularGifs'}
                elements = {['Angular', 'TypeScript','Components','Api Keys', 'LocalStorage']}
                url = {'https://angulargifs.netlify.app/'}
                type = 'angular'
                buttontext={['Show Demo','Ver Demo']}
            />
        </>
    )
}

export default Angular
