import React, { useContext } from 'react';
import { NavegacionContext } from '../../../context/NavegacionProvider';
import DesingSVG from './DesingSVG'

const DesingSkills = () => {
    const {navIdiom} = useContext(NavegacionContext);
    return (
        <>
            <h2 className="h2-skills">
                {navIdiom === 'EN' && 'Desing technologies'}
                {navIdiom === 'ES' && 'tecnologías de Diseño'}
            </h2>
            <DesingSVG />  
        </>
    )
}

export default DesingSkills
